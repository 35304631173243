import React from "react"
import VideoBox from "../components/AboutUs/videoBox"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/Shared/innerHero"

function AboutUs() {
  return (
    <Layout>
      <Seo title="About Us" />
      <InnerHero title="About Us" />
      <section className="bold-bottom--border pt-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-header text-center has--bg mb-0">
                <div className="header-image">
                  <img src="/elements/header-el-3.png" alt="no surrender" />
                </div>
                <h2 className="section-title">About Us</h2>
                <p>
                  NO SURRENDER is a Tech Company Focused on Entertainment. Our
                  mission is to revolutionize the world of online gaming with
                  Game first philosophy.
                </p>
                <p>
                  We are eager to design and create something for every kind for
                  the broadest markets.
                </p>
                <p>
                  Our goal is to diversify gaming with our cutting-edge
                  technology.
                </p>
                <p>
                  Currently, we are working on mid-core mobile games but are
                  also eager to build experiences on other platforms.
                </p>
                {/* <VideoBox></VideoBox> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="about-thumb text-center">
                <img
                  src="/elements/about-illustration.png"
                  alt="no-surrender"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-120 pb-120">
        <div className="container">
          {/* <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-header text-center has--bg">
                <div className="header-image">
                  <img src="/elements/header-el.png" alt="no surrender" />
                </div>
                <h2 className="section-title">Passionate Team</h2>
                <p>
                  Though our team has grown over the years and includes a mix of talent, our leaders have been developing games since flash games
                </p>
              </div>
            </div>
          </div> */}
          {/* row end*/}
          {/* <div className="row mb-none-30 justify-content-center">
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/elements/cat.jpg" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Çağlar Gökçe</h3>
                  <span className="designation">CO-FOUNDER</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/team/2.png" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Berke Dikmen</h3>
                  <span className="designation">CO-FOUNDER</span>
                </div>
              </div>

            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/team/3.png" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Anıl Devran</h3>
                  <span className="designation">CCO</span>
                </div>
              </div>

            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/team/3.png" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Mustafa Demirtaş</h3>
                  <span className="designation">Game Developer</span>
                </div>
              </div>

            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/team/3.png" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Utku Namlıses</h3>
                  <span className="designation">Game Artist</span>
                </div>
              </div>

            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/team/3.png" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Umut Can Eryıldız</h3>
                  <span className="designation">Game Developer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/team/3.png" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Gökay Özdeğer</h3>
                  <span className="designation">Game Developer</span>
                </div>
              </div>

            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-card">
                <div className="team-card__thumb">
                  <img src="/team/3.png" alt="no surrender" />
                  <div className="obj">
                    <img src="/elements/team-obj.png" alt="no surrender" />
                  </div>
                </div>
                <div className="team-card__content">
                  <h3 className="name">Ahmet Yılmaz</h3>
                  <span className="designation">Game Developer</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export default AboutUs
